import * as _ from 'lodash'
import { bindObjectFunctions, isClassicEditor, isResponsiveEditor, isAppMarketRequest } from '../../utils/utils'
import translations from '../../utils/translations'
import Experiments from '@wix/wix-experiments'
import { createCollectionsApi as createWixCodeCollectionsApi } from '@wix/wix-code-collections-api'
import * as createEditorTransportLayer from '@wix/wix-code-collections-transport-editor'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import { ORIGINS } from '../../constants/bi'
import * as Raven from 'raven-js'
import RemoteApi from '../../panels/commons/remote-api'
import { FormPreset } from '../../constants/form-types'
import CoreApi from '../core/core-api'
import { createPanelDefs } from '../core/services/panel-definitions'
import { setBiLogger, setPanelDefinitions } from '../editor-app-impl'
import { addForm, openAddFormPanel } from '../core-actions'
import { fetcher } from '../../utils/utils'
import { fetchAllThemes } from '../core/preset/themes-service'

const fetchCoreApi = fetcher()
export const getApi = (): Promise<CoreApi> => fetchCoreApi.getData // race-condition editorReady & onEvent

export const startApp = async origin => {
  if (!origin) {
    return
  }

  const api: CoreApi = await getApi()
  api.updateOrigin(origin)

  const addFormWithPresetFromAddPanel = _.get(origin, 'info.preset')

  if (addFormWithPresetFromAddPanel) {
    const payload = {
      containerRef: origin.info.containerRef,
      coords: origin.info.coords,
      theme: origin.info.theme,
      source_name: ORIGINS.ADD_PANEL,
      select_form: origin.info.select_form,
    }

    if (
      api.shouldEnforceFormsNumber() &&
      (await api.addForm.preventFormAdditionAddPanel(
        addFormWithPresetFromAddPanel,
        payload.containerRef
      ))
    ) {
      return
    }

    const createCollection = api.shouldCreateAutoCollection()

    if (createCollection) {
      return api.addForm.addFormWithCollection(addFormWithPresetFromAddPanel, payload)
    } else {
      await api.addForm.addForm(addFormWithPresetFromAddPanel, payload)
      return api.saveSite()
    }
  } else if (_.get(origin, 'info.type') === FormPreset.REGISTRATION_FORM) {
    await addForm(origin.info.preset || FormPreset.REGISTRATION_FORM, {
      containerRef: origin.info.containerRef,
      source_name: ORIGINS.SIGNUP_SETTINGS,
    })

    return api.saveSite()
  } else if (isClassicEditor(origin) || (isResponsiveEditor(origin) && isAppMarketRequest(origin))) {
    return openAddFormPanel()
  }
}

export const initApp = async ({ appDefinitionId, editorSDK, msid, origin, fedopsLogger }) => {
  const { coreApi, boundEditorSDK, biLogger, experiments } = await initCoreApi({
    appDefinitionId,
    editorSDK,
    msid,
    origin,
    fedopsLogger,
  })
  const [locale] = await Promise.all([
    boundEditorSDK.environment.getLocale(),
    boundEditorSDK.editor.setAppAPI(coreApi.generateRuntimeApi()),
  ])
  await translations.init(locale, experiments.all())

  setBiLogger(biLogger)

  coreApi.appState.setState()
  fetchCoreApi.resolveData(coreApi)

  const panelDefinitions = createPanelDefs(msid)
  setPanelDefinitions(panelDefinitions)

  fetchAllThemes(Raven)

  coreApi.steps.updateMultiStepFormsTitles()
}

const initCoreApi = async ({ appDefinitionId, editorSDK, msid, origin, fedopsLogger }) => {
  const experiments = new Experiments({ baseUrl: 'https://www.wix.com' })
  await experiments.load('wix-form-builder')
  const boundEditorSDK = bindObjectFunctions(editorSDK, appDefinitionId)
  const transportLayer = createEditorTransportLayer(editorSDK, appDefinitionId)
  const collectionsApi = createWixCodeCollectionsApi({ transportLayer })

  const remoteApi = new RemoteApi({ boundEditorSDK, experiments, ravenInstance: Raven })
  const biLogger = await initBiLoggerForEditorApp(msid, origin)

  const coreApi = new CoreApi(boundEditorSDK, editorSDK, {
    apis: {
      collectionsApi,
      remoteApi,
    },
    experiments,
    ravenInstance: Raven,
    biLogger,
    origin,
    appDefinitionId,
    fedopsLogger,
  })

  return { coreApi, boundEditorSDK, biLogger, experiments }
}
