import * as _ from 'lodash'
import CoreApi from '../core-api'
import { ComponentConnection } from '../../../constants/api-types'
import { ROLE_MESSAGE, ROLE_SUBMIT_BUTTON } from '../../../constants/roles'
import { RegistrationFieldPreset, FieldPreset } from '../../../constants/field-types'

// TODO: Move this to single point of configuration
const CrucialFieldPreset = {
  LOGIN_EMAIL: RegistrationFieldPreset.REGISTRATION_FORM_LOGIN_EMAIL,
  PASSWORD: RegistrationFieldPreset.REGISTRATION_FORM_PASSWORD,
  LINK_TO_LOGIN: RegistrationFieldPreset.REGISTRATION_FORM_LINK_TO_LOGIN,
}

export const REGISTRATION_FORM_CRUCIAL_FIELD_PRESET_VALUES: FieldPreset[] = _.values(CrucialFieldPreset)

const RegistrationFormApi = (coreApi: CoreApi): Partial<CoreApi> => {
  const getCrucialElements = async (
    componentRef: ComponentRef,
    componentConnection: ComponentConnection
  ) => {
    const { controllerRef } = componentConnection

    const missingFields = await Promise.all([
      coreApi.isFieldMissingByRole(componentRef, ROLE_MESSAGE),
      coreApi.isFieldMissingByRole(componentRef, ROLE_SUBMIT_BUTTON), // TODO: Combine to single call
      coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.LOGIN_EMAIL),
      coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.PASSWORD),
      coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.LINK_TO_LOGIN),
    ])

    return _.filter(missingFields)
  }

  return {
    settings: {
      getCrucialElements: getCrucialElements,
    },
  }
}

export default RegistrationFormApi
