export const enum PAYMENT_STATUS {
  UPGRADE_TO_ASCEND = 'upgrade-to-ascend',
  MISSING_PAYMENT_METHOD = 'missing-payment-method',
  CONNECTED = 'connected',
  GET_STARTED = 'get-started'
}

export const LEARN_MORE_HELP_ID = '817df5e6-fcbf-45e7-a18a-52e2dc53227b'

export enum BI_ACTIONS {
  OPEN_PAYMENT_OVERVIEW = 'Open Payment Overview',
  CHANGE_PAYMENT_METHOD = 'Change Payment Method',
  REMOVE_PAYMENT = 'Remove Payment from this site'
}

export const CURRENCY_DATA = {
  BIF: { symbol: 'FBu' },
  AUD: { symbol: 'AU$' },
  CVE: { symbol: '$' },
  KMF: { symbol: 'CF' },
  CDF: { symbol: 'FC' },
  DJF: { symbol: 'Fdj' },
  ERN: { symbol: 'ናቕፋ' },
  GNF: { symbol: 'FG' },
  LSL: { symbol: 'L' },
  LYD: { symbol: 'ل.د' },
  MWK: { symbol: 'MK' },
  MRO: { symbol: 'UM' },
  SLL: { symbol: 'LE' },
  SZL: { symbol: 'L' },
  TJS: { symbol: 'TJS' },
  TOP: { symbol: 'T$' },
  XPF: { symbol: 'F' },
  XOF: { symbol: 'CFA' },
  XAF: { symbol: 'FCFA' },
  WST: { symbol: 'WS$' },
  VUV: { symbol: 'VT' },
  UGX: { symbol: 'USh' },
  TZS: { symbol: 'TSh' },
  STD: { symbol: 'Db' },
  RWF: { symbol: 'R₣' },
  PGK: { symbol: 'K' },
  MVR: { symbol: 'Rf' },
  MOP: { symbol: 'MOP$' },
  MMK: { symbol: 'K' },
  MDL: { symbol: 'L' },
  HTG: { symbol: 'G' },
  GMD: { symbol: 'D' },
  GEL: { symbol: 'GEL' },
  ETB: { symbol: 'Br' },
  BTN: { symbol: 'Nu.' },
  BDT: { symbol: '৳' },
  AOA: { symbol: 'Kz' },
  AED: { symbol: 'AED' },
  MGA: { symbol: 'Ar' },
  GHS: { symbol: 'GH₵' },
  TMT: { symbol: 'T' },
  DZD: { symbol: 'دج' },
  MAD: { symbol: 'د.م.' },
  KWD: { symbol: 'د.ك' },
  TND: { symbol: 'د.ت' },
  AZN: { symbol: 'ман' },
  BGL: { symbol: 'лв' },
  BGN: { symbol: 'лв' },
  UZS: { symbol: 'сум' },
  KZT: { symbol: '₸' },
  KGS: { symbol: 'сом' },
  RSD: { symbol: 'РСД' },
  MKD: { symbol: 'ден' },
  TTD: { symbol: 'TT$' },
  PEN: { symbol: 'S/.' },
  SOS: { symbol: 'S' },
  IDR: { symbol: 'Rp' },
  DOP: { symbol: 'RD$' },
  ZAR: { symbol: 'R' },
  GTQ: { symbol: 'Q' },
  BYN: { symbol: 'BYR' },
  BWP: { symbol: 'P' },
  MZN: { symbol: 'MT' },
  ALL: { symbol: 'Lek' },
  RON: { symbol: 'lei' },
  HNL: { symbol: 'L' },
  KES: { symbol: 'Ksh' },
  ISK: { symbol: 'kr' },
  HRK: { symbol: 'kn' },
  BAM: { symbol: 'KM' },
  JOD: { symbol: 'JD' },
  JMD: { symbol: 'J$' },
  PYG: { symbol: 'Gs' },
  AWG: { symbol: 'ƒ' },
  ANG: { symbol: 'ƒ' },
  NIO: { symbol: 'C$' },
  BZD: { symbol: 'BZ$' },
  VEF: { symbol: 'Bs' },
  PAB: { symbol: 'B/.' },
  YER: { symbol: '﷼' },
  SAR: { symbol: '﷼' },
  QAR: { symbol: '﷼' },
  OMR: { symbol: '﷼' },
  SCR: { symbol: '₨' },
  PKR: { symbol: '₨' },
  NPR: { symbol: '₨' },
  MUR: { symbol: '₨' },
  LKR: { symbol: '₨' },
  UAH: { symbol: '₴' },
  MNT: { symbol: '₮' },
  LAK: { symbol: '₭' },
  VND: { symbol: '₫' },
  KRW: { symbol: '₩' },
  NGN: { symbol: '₦' },
  CRC: { symbol: '₡' },
  KHR: { symbol: '៛' },
  AFN: { symbol: '؋' },
  CNY: { symbol: '¥' },
  FKP: { symbol: '£' },
  SHP: { symbol: '£' },
  GIP: { symbol: '£' },
  LBP: { symbol: '.ل.ل' },
  EGP: { symbol: '£' },
  UYU: { symbol: '$U' },
  BOB: { symbol: '$b' },
  BMD: { symbol: 'BD$' },
  SBD: { symbol: '$' },
  XCD: { symbol: '$' },
  LRD: { symbol: '$' },
  KYD: { symbol: '$' },
  GYD: { symbol: '$' },
  FJD: { symbol: '$' },
  COP: { symbol: '$' },
  CLP: { symbol: '$' },
  BSD: { symbol: '$' },
  BND: { symbol: '$' },
  BBD: { symbol: '$' },
  ARS: { symbol: '$' },
  NAD: { symbol: '$' },
  SRD: { symbol: '$' },
  BHD: { symbol: '.د.ب' },
  AMD: { symbol: 'AMD' },
  TRY: { symbol: '₺' },
  INR: { symbol: '₹' },
  THB: { symbol: '฿' },
  IQD: { symbol: 'ع.د' },
  ZMW: { symbol: 'ZK' },
  BRL: { symbol: 'R$' },
  CAD: { symbol: 'C$' },
  CZK: { symbol: 'Kč' },
  DKK: { symbol: 'kr' },
  EUR: { symbol: '€' },
  HUF: { symbol: 'Ft' },
  HKD: { symbol: 'HK$' },
  ILS: { symbol: '₪' },
  PKG: { symbol: 'K' },
  MYR: { symbol: 'RM' },
  MXN: { symbol: '$' },
  TWD: { symbol: 'NT$' },
  NZD: { symbol: '$' },
  NOK: { symbol: 'Nkr' },
  PHP: { symbol: '₱' },
  GBP: { symbol: '£' },
  RUB: { symbol: 'руб' },
  SGD: { symbol: 'S$' },
  SEK: { symbol: 'kr' },
  CHF: { symbol: 'CHF' },
  USD: { symbol: '$' },
  JPY: { symbol: '¥' },
  PLN: { symbol: 'zł' },
}
