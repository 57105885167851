import { GeneralDatePicker } from '../../general-fields/definitions/general-date-picker'
import { FormsFieldPreset } from '../../../../../../constants/field-types'

export class CrmDate extends GeneralDatePicker {
  protected get fieldType() {
    return FormsFieldPreset.CRM_DATE
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.GENERAL_DATE_PICKER}`)
  }
}
