export enum NOTIFICATION_EVENTS {
  PAYMENT_FORM_ADDED = 'paymentFormAdded',
  SUCCESS_MESSAGE_DELETED = 'messageRole',
  DOWNLOAD_MESSAGE_DELETED = 'downloadMessageRole',
  EMAIL_FIELD_DELETED = 'email',
  SUBMISSION_BUTTON_DELETED = 'buttonRole',
  PREVIOUS_BUTTON_DELETED = 'previousButton',
  NEXT_BUTTON_DELETED = 'nextButton'
}

