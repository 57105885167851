import { FormsFieldPreset, FieldPreset  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'

export class GeneralSingleCheckbox extends IGeneralField {
  protected get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_SINGLE_CHECKBOX
  }

  protected get icon() {
    return iconNames.singleCheckbox
  }

  protected get collectionFieldType() {
    return FieldCollectionType.BOOLEAN
  }

  protected get componentType() {
    return COMPONENT_TYPES.SINGLE_CHECKBOX
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_CHECKBOX
  }

  protected get crmType() {
    return CRM_TYPES.CUSTOM_FIELD
  }

  protected get data(): any {
    return {
      label: this.translate(`preset.${FormsFieldPreset.GENERAL_SINGLE_CHECKBOX}Label`),
    }
  }

  public get base() {
    return GeneralSingleCheckbox.prototype
  }
}
