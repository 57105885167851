import * as _ from 'lodash'
import { isAnyField, undoable, withBi } from '../utils'
import CoreApi from '../core-api'
import { calcCommonStyleGlobalDesign, commonStyles } from '../services/form-style-service'
import { getTheme } from '../preset/themes-service'
import { customStyleHandlers } from '../preset/preset-styles'
import { innerText } from '../../../utils/utils'
import { EVENTS } from '../../../constants/bi'
import { roleDesignMapping } from '../manifests/global-design-manifest'
import { ROLE_FORM } from '../../../constants/roles'
import { COMPONENT_TYPES } from '../../../constants/component-types'

export default class StyleApi {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private biLogger: any
  private experiments: any

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
  }

  public async getFieldsCommonStylesGlobalDesign(
    componentRef: ComponentRef
  ): Promise<commonStyles> {
    const compStyle = await this.boundEditorSDK.components.style.get({ componentRef })
    const formStyle = compStyle || { style: { properties: {} } }

    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const childrenRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const fieldsTypes = await Promise.all(
      childrenRefs.map(async child => {
        const type = await this.boundEditorSDK.components.getType({ componentRef: child })
        return { type }
      })
    )
    const fieldsStyleAndConnections = await this.boundEditorSDK.components.get({
      componentRefs: childrenRefs,
      properties: ['style', 'connections'],
    })
    const children = _.merge(fieldsTypes, fieldsStyleAndConnections)
    const fields = _.flatMap(children, ({ connections, style, type }) => {
      const fieldRole = _.get(connections, '[0].role')
      return isAnyField(fieldRole)
        ? {
            style: _.get(style, 'style.properties'),
            designMapping: _.get(roleDesignMapping, [fieldRole, type]) || [],
          }
        : []
    })

    return calcCommonStyleGlobalDesign([
      ...fields,
      {
        style: formStyle,
        designMapping: roleDesignMapping[ROLE_FORM][COMPONENT_TYPES.FORM_CONTAINER],
      },
    ])
  }

  private async _updateThemeStyle(componentRef: ComponentRef, style, customStyleHandler) {
    if (!style) {
      return
    }
    if (!_.isString(style)) {
      return this.boundEditorSDK.components.style.update({ componentRef, style })
    }

    const { text } = await this.boundEditorSDK.components.data.get({ componentRef })
    const newText = customStyleHandler
      ? customStyleHandler({style, text})
      : _.replace(style, 'TITLE', innerText(text))

    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { text: newText },
    })
  }

  private _updateThemeProps(componentRef: ComponentRef, props) {
    if (!props) {
      return
    }
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props,
    })
  }

  private _updateThemeData(componentRef: ComponentRef, data) {
    if (!data) {
      return
    }
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data,
    })
  }

  public async getTheme(componentRef: ComponentRef) {
    const formConnection = await this.coreApi.getComponentConnection(componentRef)
    return _.get(formConnection, 'config.theme')
  }

  private async _updateTheme(componentRef: ComponentRef, theme: string) {
    if (await this.coreApi.isAppWidget(componentRef)) {
      componentRef = await this.coreApi.getFormContainerOfAppWidget(componentRef)
    }

    const { config } = await this.coreApi.getComponentConnection(componentRef)
    const themeFromConfig = _.get(config, 'theme')

    if (theme === themeFromConfig) {
      return
    }

    await this.coreApi.setComponentConnection(componentRef, { theme })
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const children = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })

    const { stylesByRole, propsByRole, dataByRole } = await getTheme(theme)

    if (_.isEmpty(stylesByRole)) {
      await this.coreApi.logFetchThemesFailed(componentRef, `theme ${theme} not found in resources`)
    }

    return Promise.all(
      _.map(children, async (childRef: ComponentRef) => {
        const { role } = await this.coreApi.getComponentConnection(childRef)

        return Promise.all([
          this._updateThemeProps(childRef, propsByRole[role]),
          this._updateThemeData(childRef, dataByRole[role]),
          this._updateThemeStyle(childRef, stylesByRole[role], customStyleHandlers[role]),
        ])
      })
    )
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formStylePanel.CUSTOM_DESIGN_ACTION })
  public async updateTheme(componentRef: ComponentRef, theme: string, _biData = {}) {
    return this._updateTheme(componentRef, theme)
  }

  public async updateFieldPresetTheme(fieldPreset, theme: string) {
    if (!theme) return fieldPreset

    const role = _.get(fieldPreset, 'role')
    const { stylesByRole, propsByRole, dataByRole } = await getTheme(theme)

    const roleStyles = stylesByRole[role]
    const roleProps = propsByRole[role]
    const roleData = dataByRole[role]

    let data = roleData ? roleData : {}
    let props = roleProps ? roleProps : {}
    let style = !_.isString(roleStyles)
      ? {
          style: {
            properties: roleStyles ? roleStyles : {},
          },
        }
      : {}

    if (_.isString(roleStyles)) {
      const text = _.get(fieldPreset, 'data.data.text', '')
      const customStyleHandler = customStyleHandlers[role]
      const newText = customStyleHandler
        ? customStyleHandler({ style, text })
        : _.replace(roleStyles, 'TITLE', innerText(text))

      data = {
        ...data,
        text: newText,
      }
    }

    const styledFieldPreset = _.merge({}, fieldPreset, {
      data: {
        props,
        data,
        style,
      },
    })

    return styledFieldPreset
  }

  public async updateThemeADI(componentRef: ComponentRef, theme: string) {
    return this._updateTheme(componentRef, theme)
  }

  public async updateComponentTheme(componentRef, theme) {
    const { role } = await this.coreApi.getComponentConnection(componentRef)
    const { stylesByRole, propsByRole, dataByRole } = await getTheme(theme)

    return Promise.all([
      this._updateThemeProps(componentRef, propsByRole[role]),
      this._updateThemeData(componentRef, dataByRole[role]),
      this._updateThemeStyle(componentRef, stylesByRole[role], customStyleHandlers[role]),
    ])
  }
}
