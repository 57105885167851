import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'

export const makeGeneralCheckboxOption = () => ({
  type: 'CheckboxInput',
  metaData: {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
  },
  value: '',
  label: '',
  checked: true,
})

export class GeneralCheckbox extends IGeneralField {
  protected get icon() {
    return iconNames.multiSelectCheckbox
  }

  protected get fieldType() {
    return FormsFieldPreset.GENERAL_CHECKBOX
  }

  protected get collectionFieldType() {
    return FieldCollectionType.TAGS
  }

  protected get componentType() {
    return COMPONENT_TYPES.CHECKBOX_GROUP
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_CHECKBOX_GROUP
  }

  protected get crmType() {
    return CRM_TYPES.CUSTOM_FIELD
  }

  protected get data(): any {
    return {
      placeholder: this.translate(DEFAULT_PLACEHOLDER),
      label: this.translate(`preset.${this.fieldType}Label`),
      options: [
        {
          ...makeGeneralCheckboxOption(),
          value: this.translate(`preset.${this.fieldType}FirstOptionsValue`),
          label: this.translate(`preset.${this.fieldType}FirstOptionsLabel`),
        },
        {
          ...makeGeneralCheckboxOption(),
          value: this.translate(`preset.${this.fieldType}SecondOptionsValue`),
          label: this.translate(`preset.${this.fieldType}SecondOptionsLabel`),
        },
        {
          ...makeGeneralCheckboxOption(),
          value: this.translate(`preset.${this.fieldType}ThirdOptionsValue`),
          label: this.translate(`preset.${this.fieldType}ThirdOptionsLabel`),
          checked: false,
        },
        {
          ...makeGeneralCheckboxOption(),
          value: this.translate(`preset.${this.fieldType}ForthOptionsValue`),
          label: this.translate(`preset.${this.fieldType}ForthOptionsLabel`),
          checked: false,
        },
      ],
    }
  }

  public get base() {
    return GeneralCheckbox.prototype
  }
}
