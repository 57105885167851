import * as _ from 'lodash'
import translations from '../../../utils/translations'
import { ROLE_FORM } from '../../../constants/roles'
import { GFPP, GFPP_IDS, GFPP_ACTIONS } from './manifest-commons'
import { roleDesignMapping } from './global-design-manifest'

export const stepButtonManifest = role => ({
  relatedToRole: ROLE_FORM,
  displayName: translations.t(`multiStepForm.${_.camelCase(role)}.fieldName`),
  behavior: { duplicatable: false },
  gfpp: {
    desktop: {
      mainAction1: {
        label: translations.t('changeButtonLabelPanel.gfppTitle'),
        actionId: GFPP_IDS.CHANGE_BUTTON_LABEL,
      },
      mainAction2: GFPP.REMOVE,
      iconButtons: {
        [GFPP_ACTIONS.LINK]: GFPP.REMOVE,
        [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
      },
      helpId: GFPP.HELP_ID.SUBMIT_BUTTON, // FIXME - set correct helpId
    },
    mobile: {
      /* use default configurations */
    },
  },
  designMapping: roleDesignMapping[role]
})
