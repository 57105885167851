import { FormsFieldPreset, FieldPreset } from '../../../../../../constants/field-types'
import { GeneralText } from '../../general-fields/definitions/general-text'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

export class Phone extends GeneralText {
  protected get customFields() {
    return []
  }

  protected get fieldType(): FieldPreset {
    return FormsFieldPreset.PHONE
  }

  protected get icon() {
    return iconNames.phone
  }

  protected get crmType() {
    return CRM_TYPES.PHONE
  }

  protected get crmTag() {
    return CRM_TAGS.OTHER
  }

  protected get data(): any {
    return {
      textType: 'tel',
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
    }
  }
}
