import { GeneralText } from '../../general-fields/definitions/general-text'
import { FormsFieldPreset } from '../../../../../../constants/field-types'

export class CrmText extends GeneralText {
  protected get fieldType() {
    return FormsFieldPreset.CRM_TEXT
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.GENERAL_TEXT}`)
  }
}
