import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { GeneralDatePicker } from '../../general-fields/definitions/general-date-picker'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'

export class Birthday extends GeneralDatePicker {
  protected get customFields() {
    return []
  }

  protected get fieldType() {
    return FormsFieldPreset.BIRTHDAY
  }

  protected get crmType() {
    return CRM_TYPES.DATE
  }

  protected get crmTag() {
    return CRM_TAGS.BIRTHDAY
  }

  protected get data(): any {
    return { placeholder: this.translate(`fieldTypes.${this.fieldType}`) }
  }
}
