import * as _ from 'lodash'
import { createFormContainerManifest } from './box-manifest'
import { createFieldsManifests } from './field-manifest'
import {
  FIELDS_ROLES,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  ROLE_TITLE,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
  REGISTRATION_FIELDS_ROLES,
} from '../../../constants/roles'
import { createSubmitButtonManifest } from './submit-button-manifest'
import { createHiddenMessageManifest } from './hidden-message-manifest'
import { createTitleManifest } from './title-manifest'
import { AppStateBuilder, AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '../../../constants/plugins'
import { MessageType } from '../../../constants/field-types'
import { ControllerType } from '../../../constants/api-types'
import { createMultiStepManifest } from './multi-step-manifest'
import { createFirstTimeExperienceManifest } from './first-time-experience-manifest'
import { stepButtonManifest } from './step-button-manifest'

export const OLD_CONTROLLER_TYPE = 'singlePostController'

const ALWAYS_HIDE_CONTROLLER = 'NEVER'
const plugins = [
  FormPlugin.FORM_BUILDER,
  FormPlugin.GET_SUBSCRIBERS,
  FormPlugin.REGISTRATION_FORM,
  FormPlugin.MULTI_STEP_FORM,
]
const states = _.flatMap(plugins, plugin =>
  _.flatMap(
    [true, false],
    duplicatable =>
      new AppStateBuilder({
        duplicatable,
        plugins: [plugin],
      })
  )
).filter(x => x.toString())

const statesByPlugin: { [key in FormPlugin]?: AppStateBuilder[] } = plugins.reduce(
  (acc, plugin: FormPlugin) => {
    acc[plugin] = states.filter(appState => _.includes(appState.get().plugins, plugin))
    return acc
  },
  {}
)

export const getAppManifest = ({
  connectFieldGfpp,
  isTopPremium,
  replaceManageFieldsWithAddFieldGffp,
  shouldEnableCopyPasteFields,
  isResponsive,
}) => {
  const enhanceState = state => ({
    ...state,
    connectFieldGfpp,
    isTopPremium,
    replaceManageFieldsWithAddFieldGffp,
    shouldEnableCopyPasteFields,
    isResponsive,
  })

  const createFieldManifests = state => {
    let filteredFieldsRoles = FIELDS_ROLES

    if (!_.includes(state.plugins, FormPlugin.REGISTRATION_FORM)) {
      filteredFieldsRoles = _.difference(filteredFieldsRoles, REGISTRATION_FIELDS_ROLES)
    }

    const fieldsManifest = createFieldsManifests(state)

    return filteredFieldsRoles.reduce(
      (res, fieldRole) => ({
        ...res,
        [fieldRole]: fieldsManifest[fieldRole],
      }),
      {}
    )
  }

  const titleManifest = createTitleManifest()
  const createManifest = (appState: AppStateObject, isDefault = false) => {
    const state = enhanceState(appState)
    const isMultiStepForm = _.includes(state.plugins, FormPlugin.MULTI_STEP_FORM)
    const { box, steps } = isMultiStepForm
      ? createMultiStepManifest(state)
      : createFormContainerManifest(state)

    let pluginsMainRoles = {}

    if (isMultiStepForm) {
      pluginsMainRoles = {
        [ROLE_PREVIOUS_BUTTON]: stepButtonManifest(ROLE_PREVIOUS_BUTTON),
        [ROLE_NEXT_BUTTON]: stepButtonManifest(ROLE_NEXT_BUTTON),
      }
    }

    let boxManifest: any = box

    if (isDefault) {
      boxManifest = _.omit(boxManifest, 'gfpp.desktop.globalDesign')
    }

    return {
      visibility: ALWAYS_HIDE_CONTROLLER,
      ...boxManifest,
      connections: isDefault
        ? {}
        : {
            [ROLE_FORM]: box,
            [ROLE_SUBMIT_BUTTON]: createSubmitButtonManifest(state),
            [ROLE_MESSAGE]: createHiddenMessageManifest(state),
            [ROLE_DOWNLOAD_MESSAGE]: createHiddenMessageManifest(state, MessageType.DOWNLOAD),
            [ROLE_TITLE]: titleManifest,
            ...pluginsMainRoles,
            ...steps,
            ...createFieldManifests(state),
          },
      ...createFirstTimeExperienceManifest(state),
    }
  }

  const createDefaultStateWithStates = (states, oldController = false) => ({
    default: createManifest({}, true),
    ...states.reduce((agg, state) => {
      const stateData = state.get()

      if (_.includes(stateData.plugins, FormPlugin.MULTI_STEP_FORM)) {
        // ignore multi step state that isn't duplicatable (not valid state)
        if (!stateData.duplicatable) {
          return agg
        }

        // ignore multi step state for old controller (not valid state)
        if (oldController) {
          return agg
        }
      }

      agg[state.toString()] = createManifest(state.get())
      return agg
    }, {}),
  })

  return {
    controllersStageData: {
      singlePostController: createDefaultStateWithStates(states, true), //TODO: [PERFORMANCE] UPDATE CONTROLLER ON INIT AND REMOVE THIS CONTROLLER TYPE
      [ControllerType.WIX_FORMS]: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.FORM_BUILDER]
      ),
      [ControllerType.GET_SUBSCRIBERS]: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.GET_SUBSCRIBERS]
      ),
      [ControllerType.REGISTRATION_FORM]: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.REGISTRATION_FORM]
      ),
      [ControllerType.MULTI_STEP_FORM]: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.MULTI_STEP_FORM]
      ),
    },
  }
}
