import * as _ from 'lodash'
import CoreApi from '../core-api'
import RegistrationFormApi from './registration-form'
import { FormPlugin } from '../../../constants/plugins'
import { Plugin } from '../../../constants/api-types'
import { getActivePlugin } from './utils'

export default class PluginsApi {
  private pluginsApi: { [key in FormPlugin]?: Partial<CoreApi> }

  constructor(coreApi: CoreApi) {
    this.pluginsApi = {
      [FormPlugin.REGISTRATION_FORM]: RegistrationFormApi(coreApi)
    }
  }

  all() {
    return this.pluginsApi
  }

  withPlugins(plugins: Plugin[]) {
    const plugin = this._getActivePlugin(plugins)

    return {
      supportApi: apiPath => this._supportApi(plugin, apiPath),
      callApi: (apiPath, ...payload) => this._callApi(plugin, apiPath, ...payload),
    }
  }

  private _getActivePlugin(plugins: Plugin[]): FormPlugin {
    const formPlugins: FormPlugin[] = _.map(plugins, 'id')
    return getActivePlugin(formPlugins)
  }

  private _supportApi(plugin, apiPath): boolean {
    return !!_.get(this.pluginsApi[plugin], apiPath)
  }

  private _callApi(plugin, apiPath, ...payload) {
    if (!this._supportApi(plugin, apiPath)) {
      throw new Error(`${plugin}.${apiPath} not implemented`)
    }

    return _.invoke(this.pluginsApi[plugin], apiPath, ...payload)
  }
}
