import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { iconNames } from '../../icons/types'
import { FormPlugin } from '../../../../../../constants/plugins'
import { FieldRenderConfigType } from '../../constants'
import { IGeneralField } from '../../general-field'

export class GeneralRecaptcha extends IGeneralField {
  protected get customFields() {
    return []
  }

  protected get icon() {
    return iconNames.recaptcha
  }

  protected get fieldType() {
    return FormsFieldPreset.GENERAL_RECAPTCHA
  }

  protected get collectionFieldType() {
    return FieldCollectionType.BOOLEAN
  }

  protected get componentType() {
    return COMPONENT_TYPES.RECAPTCHA
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_RECAPTCHA
  }

  protected get props() {
    return {
      required: true
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        isEditable: false,
        required: FieldRenderConfigType.REMOVE,
      }
    }
  }

  protected get allowOnlyOnce() {
    return true
  }

  protected get disableCollectionSync() {
    return true
  }

  public get base() {
    return GeneralRecaptcha.prototype
  }
}
