import { FormPreset, ResponsiveFormPreset } from '../../../constants/form-types'

export const MASTER_PAGE = { type: 'DESKTOP', id: 'masterPage' }

export const OLD_PRESET_TYPES = [
  FormPreset.CONTACT_FORM,
  FormPreset.GET_SUBSCRIBERS,
  FormPreset.SALES_LEAD,
  FormPreset.SUPPORT_REQUEST,
  FormPreset.TESTIMONIAL,
  FormPreset.JOB_APPLICATION,
  FormPreset.CUSTOMER_SATISFACTION,
  FormPreset.PRICE_QUOTE,
  FormPreset.CONTEST_ENTRY,
  FormPreset.QUIZ,
]

export const NEW_PRESET_TYPES = [
  FormPreset.CONTACT_FORM,
  FormPreset.GET_SUBSCRIBERS,
  FormPreset.PAYMENT_FORM,
  FormPreset.MULTI_STEP_REGISTRATION_FORM,
  FormPreset.SALES_LEAD,
  FormPreset.SUPPORT_REQUEST,
  FormPreset.TESTIMONIAL,
  FormPreset.JOB_APPLICATION,
  FormPreset.CUSTOMER_SATISFACTION,
  FormPreset.PRICE_QUOTE,
  FormPreset.CONTEST_ENTRY,
  FormPreset.QUIZ,
]

export const RESPONSIVE_PRESET_TYPES = [
  ResponsiveFormPreset.CONTACT,
  ResponsiveFormPreset.SUBSCRIBERS,
  ResponsiveFormPreset.SALES_LEAD,
  ResponsiveFormPreset.JOB_APPLICATION,
]
