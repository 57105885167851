import { AdiFieldPreset } from '../../../../../../constants/field-types'
import {
  GeneralDatePicker,
} from '../../general-fields/definitions/general-date-picker'

export class AdiDate extends GeneralDatePicker {
  protected get fieldType() {
    return AdiFieldPreset.ADI_DATE
  }

  protected get data() {
    return {
      placeholder: this.translate(
        `adi.fieldTypes.${this.base.metadata.fieldType}.placeholder`
      ),
      label: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.label`),
    }
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${this.base.metadata.fieldType}FieldEditPanel.title`
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${this.base.metadata.fieldType}`)
  }
}
