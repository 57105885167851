export const SUBMISSION_DISPLAY_FIELD = 'submissionTime'
export const PAYMENT_DISPLAY_FIELD = 'paymentTransactionId'
export const TITLE_FIELD = 'title'

export enum FieldCollectionType {
  TEXT = 'text',
  IMAGE = 'image',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  DATETIME = 'datetime',
  RICHTEXT = 'richtext',
  COLOR = 'color',
  REFERENCE = 'reference',
  MULTI_REFERENCE = 'multi-reference',
  PAGELINK = 'pagelink',
  URL = 'url',
  DOCUMENT = 'document',
  VIDEO = 'video',
  ADDRESS = 'address',
  OBJECT = 'object',
  TAGS = 'array<string>'
}

export enum ExecuteType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  RESTORE = 'RESTORE'
}

export enum OperationType {
  ADD = 'add',
  REMOVE = 'remove',
}
