import { FormsFieldPreset, FieldPreset } from '../../../../../../constants/field-types'
import { GeneralText } from '../../general-fields/definitions/general-text'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

export class LastName extends GeneralText {
  protected get customFields() {
    return []
  }

  protected get fieldType(): FieldPreset {
    return FormsFieldPreset.LAST_NAME
  }

  protected get icon() {
    return iconNames.name
  }

  protected get crmType() {
    return CRM_TYPES.LAST_NAME
  }

  protected get data() {
    return {
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
    }
  }

  protected get allowOnlyOnce() {
    return true
  }
}
