/* eslint-disable */
import { FormPreset } from '../../../constants/form-types'

export const SHOULD_CREATE_COLLECTION = {
  [FormPreset.CONTACT_FORM]: false,
  [FormPreset.POTENTIAL_CUSTOMERS]: false,
  [FormPreset.SALES_LEAD]: false,
  [FormPreset.SUPPORT_REQUEST]: true,
  [FormPreset.TESTIMONIAL]: true,
  [FormPreset.JOB_APPLICATION]: true,
  [FormPreset.CUSTOMER_SATISFACTION]: true,
  [FormPreset.PRICE_QUOTE]: true,
  [FormPreset.CONTEST_ENTRY]: false,
  [FormPreset.QUIZ]: true,
  [FormPreset.REGISTRATION_FORM]: false,
}
