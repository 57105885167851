import { createSuffixedName } from '../../../utils/utils'
import translations from '../../../utils/translations'
import { EVENTS } from '../../../constants/bi'
import * as _ from 'lodash'
import {
  FieldCollectionType,
  SUBMISSION_DISPLAY_FIELD,
  PAYMENT_DISPLAY_FIELD,
  TITLE_FIELD,
} from '../../../constants/wixcode'
import { withBi, isInputField } from '../utils'
import { Plugin, FormField } from '../../../constants/api-types'
import { allowCollectionSync } from '../preset/fields/field-types-data'
import { FormPreset } from '../../../constants/form-types'
import { findPlugin } from '../plugins/utils'
import { FormPlugin } from '../../../constants/plugins'
import {
  updateSchema,
  createSchema,
  setDisplayField,
  removeSchema,
  addField,
  updateField,
  markFieldDeleted,
  setPermissions,
  PRESETS,
  PERMISSIONS_BY_PRESET
} from '@wix/wix-code-collections-api'

const WIXCODE_APP_DEF_ID = '675bbcef-18d8-41f5-800e-131ec9e08762'

export default class CollectionsApi {
  private collectionsApi: any
  private boundEditorSDK: any
  private appDefinitionId: any
  private biLogger: any

  constructor(boundEditorSDK: any, collectionsApi: any, appDefinitionId: any, { biLogger }) {
    this.collectionsApi = collectionsApi
    this.boundEditorSDK = boundEditorSDK
    this.appDefinitionId = appDefinitionId
    this.biLogger = biLogger
  }

  public async removeCollection(collectionId: string): Promise<void> {
    return this.collectionsApi.execute(removeSchema(collectionId))
  }

  private _installWixCode() {
    if (this._isWixCodeInstalled()) {
      return Promise.resolve()
    }

    return this.boundEditorSDK.document.application.install({
      appDefinitionId: WIXCODE_APP_DEF_ID,
    })
  }

  private _isWixCodeInstalled() {
    try {
      const wixCodeApi = this.boundEditorSDK.document.application.getPublicAPI({
        appDefinitionId: 'wix-code',
      })
      return !!wixCodeApi
    } catch (ex) {
      return false
    }
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.CREATE_SUBMISSIONS_TABLE,
    endEvid: EVENTS.PANELS.settingsPanel.SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY,
  })
  public async createCollection(
    {
      preset,
      fields,
      plugins,
      fieldKeyCallback,
    }: {
      preset: FormPreset
      fields: FormField[]
      plugins: Plugin[]
      fieldKeyCallback: (component: ComponentRef, fieldKey: string) => void
    },
    _biData = {}
  ) {
    await this._installWixCode()

    const collections = await this.collectionsApi.getAll()
    const collectionId = createSuffixedName(_.map(collections, 'id'), _.camelCase(preset), '')

    const formFields = this._addFieldsToCollection(fields, fieldKeyCallback)
    const submissionTimeField = {
      pluginId: this.appDefinitionId,
      displayName: translations.t(`addForm.submissions.${SUBMISSION_DISPLAY_FIELD}`),
      type: FieldCollectionType.DATETIME,
    }
    const paymentField = {
      pluginId: this.appDefinitionId,
      displayName: translations.t(`addForm.submissions.${PAYMENT_DISPLAY_FIELD}`),
      type: FieldCollectionType.TEXT,
    }
    const paymentPlugin: Plugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
    const fieldsToAdd = [
      addField(SUBMISSION_DISPLAY_FIELD, submissionTimeField),
      ...formFields,
      paymentPlugin && paymentPlugin.payload ? addField(PAYMENT_DISPLAY_FIELD, paymentField) : null,
    ].filter(field => field)

    await this.collectionsApi.execute(
      createSchema(
        collectionId,
        ...fieldsToAdd,
        setDisplayField(SUBMISSION_DISPLAY_FIELD),
        markFieldDeleted(TITLE_FIELD),
        setPermissions(PERMISSIONS_BY_PRESET[PRESETS.FORM_INPUTS])
      )
    )

    return collectionId
  }

  private _addFieldsToCollection(
    fields: FormField[],
    fieldKeyCallback?: (component: ComponentRef, fieldKey: string) => void
  ) {
    const fieldKeys = []
    const fieldsCollectionData = _.filter(
      fields,
      ({ role, fieldType }) => isInputField(role) && allowCollectionSync(fieldType)
    ).map(({ crmLabel, collectionFieldKey, collectionFieldType, componentRef }) => {
      const fieldKey = collectionFieldKey
        ? collectionFieldKey
        : createSuffixedName(fieldKeys, _.camelCase(crmLabel), '')
      if (!collectionFieldKey) {
        fieldKeyCallback(componentRef, fieldKey)
      }

      fieldKeys.push(fieldKey)

      return {
        fieldKey,
        fieldConfig: {
          pluginId: this.appDefinitionId,
          displayName: crmLabel,
          type: collectionFieldType || FieldCollectionType.TEXT,
        },
      }
    })

    return fieldsCollectionData.map(({ fieldKey, fieldConfig }) => addField(fieldKey, fieldConfig))
  }

  public async addFieldsToCollection(
    collectionId: string,
    fields: FormField[],
    fieldKeyCallback?: (component: ComponentRef, fieldKey: string) => void
  ) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }
    return this.collectionsApi.execute(
      updateSchema(collectionId, ...this._addFieldsToCollection(fields, fieldKeyCallback))
    )
  }

  public async isCollectionExists(collectionId) {
    if (!this._isWixCodeInstalled()) {
      return false
    }

    return _.some(
      await this.collectionsApi.getAll().catch(() => {
        {
        }
      }),
      { id: collectionId }
    )
  }

  public async getCollectionMapById() {
    return _.keyBy(
      await this.collectionsApi.getAll().catch(() => {
        {
        }
      }),
      'id'
    )
  }

  public async addFieldToCollection(collectionId, fieldConnectionConfig) {
    const { collectionFieldKey, crmLabel, collectionFieldType } = fieldConnectionConfig
    if (!collectionId || !collectionFieldType) {
      return
    }

    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    await this.collectionsApi.execute(
      updateSchema(
        collectionId,
        addField(collectionFieldKey, {
          pluginId: this.appDefinitionId,
          displayName: crmLabel,
          type: collectionFieldType,
        })
      )
    )
  }

  public async updateField(collectionId, fieldKey, displayName) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    return this.collectionsApi.execute(
      updateSchema(
        collectionId,
        updateField(fieldKey, {
          pluginId: this.appDefinitionId,
          displayName,
        })
      )
    )
  }

  private async _addField(collectionId, { fieldKey, displayName, type }) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    return this.collectionsApi.execute(
      updateSchema(
        collectionId,
        addField(fieldKey, {
          pluginId: this.appDefinitionId,
          displayName,
          type,
        })
      )
    )
  }

  public addPaymentField(collectionId) {
    return this._addField(collectionId, {
      fieldKey: PAYMENT_DISPLAY_FIELD,
      displayName: translations.t(`addForm.submissions.${PAYMENT_DISPLAY_FIELD}`),
      type: FieldCollectionType.TEXT,
    })
  }
}
