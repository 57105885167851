export enum SuccessActionTypes {
  SHOW_MESSAGE = 'SHOW_MESSAGE',
  LINK = 'LINK',
  DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
}

export enum SecondsToResetDefaults {
  MIN = 3,
  MAX = 10,
}
