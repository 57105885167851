import { IGeneralField } from '../general-field'
import { FirstName } from './definitions/first-name'
import { LastName } from './definitions/last-name'
import { Company } from './definitions/company'
import { Position } from './definitions/position'
import { Email } from './definitions/email'
import { Address } from './definitions/address'
import { Phone } from './definitions/phone'
import { Birthday } from './definitions/birthady'
import { Anniversary } from './definitions/anniversary'
import { Website } from './definitions/webstie'
import { AgreeTerms } from './definitions/agree-terms'
import { CrmText } from './definitions/crm-txt'
import { CrmNumber } from './definitions/crm-number'
import { CrmUrl } from './definitions/crm-url'
import { CrmDate } from './definitions/crm-date'

export const RECOMMENDED_FIELDS: IGeneralField[] = [
  new FirstName(),
  new LastName(),
  new Company(),
  new Position(),
  new Email(),
  new Address(),
  new Phone(),
  new Birthday(),
  new Anniversary(),
  new Website(),
  new AgreeTerms(),
  new CrmText(),
  new CrmNumber(),
  new CrmUrl(),
  new CrmDate(),
]
