import * as _ from 'lodash'
import translations from '../../../utils/translations'
import {AppStateObject} from "../app-state/app-state-builder";
import { FormPlugin } from '../../../constants/plugins'

const isMultiStepForm = (plugins: FormPlugin[] = []) =>
  _.includes(plugins, FormPlugin.MULTI_STEP_FORM)

export const createFirstTimeExperienceManifest = ({plugins}: AppStateObject = {}) => {
  const {t} = translations

  if (isMultiStepForm(plugins))
    return {
      firstTimeExperience: {
        contentTitle: t('firstTimePanel.multiStep.title'),
        content: t('firstTimePanel.multiStep.subtitle'),
        headerBg: 'http://static.parastorage.com/services/wix-form-builder/1.1589.0/assets/gifs/first-time-experience/multistep-first-time.gif',
        headerBgRetina: 'http://static.parastorage.com/services/wix-form-builder/1.1589.0/assets/gifs/first-time-experience/multistep-first-time-retine.gif',
        bgColor: '#25C0C7'
      }
    }
  return {}
}