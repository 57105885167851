import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'

export class GeneralRating extends IGeneralField {
  protected get icon() {
    return iconNames.rating
  }

  protected get fieldType() {
    return FormsFieldPreset.GENERAL_RATING
  }

  protected get collectionFieldType() {
    return FieldCollectionType.NUMBER
  }

  protected get componentType() {
    return COMPONENT_TYPES.RATING
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_RATING
  }

  protected get data(): any {
    return {
      titleText: this.translate(`preset.${this.fieldType}Title`),
      svgId: '503047d3d3884f6097b82dec7af52fa8.svg',
      labels: {
        '1': this.translate(`preset.${this.fieldType}FirstOption`),
        '2': this.translate(`preset.${this.fieldType}SecondOption`),
        '3': this.translate(`preset.${this.fieldType}ThirdOption`),
        '4': this.translate(`preset.${this.fieldType}FourthOption`),
        '5': this.translate(`preset.${this.fieldType}FifthOption`),
      },
    }
  }

  public get base() {
    return GeneralRating.prototype
  }
}
