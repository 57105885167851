import * as _ from 'lodash'
import CoreApi from '../core-api'
import RemoteApi from '../../../panels/commons/remote-api'
import { AppStateBuilder } from './app-state-builder'
import { ROLE_FORM } from '../../../constants/roles'
import { FormPlugin } from '../../../constants/plugins'

export default class AppState {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private experiments: any
  private remoteApi: RemoteApi

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  private async _getFormPlugins(formRef: ComponentRef) {
    const { plugins } = await this.coreApi.getFormConfigData(formRef)
    if (_.isEmpty(plugins)) {
      return [FormPlugin.FORM_BUILDER]
    }
    return plugins
  }

  public async setState(controllerRefs?: ComponentRef[]) {
    if (!controllerRefs) {
      const controllers: {
        controllerRef: ComponentRef
      }[] = await this.boundEditorSDK.controllers.listAllControllers()
      controllerRefs = controllers.map(({ controllerRef }) => controllerRef)
    }

    controllerRefs.forEach(async ref => {
      const formRef = await this.coreApi.findConnectedComponent(ref, ROLE_FORM)

      if (!formRef) {
        return
      }

      const plugins = await this._getFormPlugins(formRef)
      const duplicatable = await this.coreApi.isAppWidget(ref)

      const stateBuilder = new AppStateBuilder({
        plugins,
        duplicatable,
      })

      const stateName = stateBuilder.toString()

      if (stateName) {
        await this.boundEditorSDK.controllers.setState({ state: { [stateName]: [ref] } })
      }
    })
  }
}
