import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { GeneralUrl } from '../../general-fields/definitions/general-url'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'

export class Website extends GeneralUrl {
  protected get customFields() {
    return []
  }

  protected get fieldType() {
    return FormsFieldPreset.WEBSITE
  }

  protected get crmType() {
    return CRM_TYPES.WEBSITE
  }

  protected get crmTag() {
    return CRM_TAGS.OTHER
  }

  protected get data() {
    return {
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
    }
  }
}
