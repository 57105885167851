import * as _ from 'lodash'
import { COMPONENT_TYPES } from '../../../constants/component-types'
import { calcDominatedValueInArray } from '../utils'
import { FieldPreset } from '../../../constants/field-types'
import { ALL_FIELDS_DATA } from '../preset/fields/field-types-data'
import {
  FormField,
  PROP_TEXT_ALIGNMENT,
  PROP_ALIGNMENT,
  PROP_LABEL_POSITION,
  PROP_DIRECTION,
  PROP_LAYOUT,
  PROPERTIES,
} from '../../../constants/api-types'
import { getDefaultLabel } from '../fields/utils'
import { FieldLayoutProp } from '../layout-panel/utils'
import { ResponsiveLayout } from '@wix/platform-editor-sdk'

// common layout group is not always from the same type, so we enforce it to be
// each group has one dominated type, that some properties will use this callbacks to align to it
const propsTransformationToCommonLayout: {
  [key: string]: {
    fromCommonLayoutToValue?: (propValue: PROPERTIES) => PROPERTIES
    fromValueToCommonLayout?: (propValue: PROPERTIES) => PROPERTIES
  }
} = {
  alignment: {
    fromCommonLayoutToValue: (value: PROP_TEXT_ALIGNMENT): PROP_ALIGNMENT =>
      value === PROP_TEXT_ALIGNMENT.CENTER || value === PROP_TEXT_ALIGNMENT.LEFT
        ? PROP_ALIGNMENT.LEFT
        : PROP_ALIGNMENT.RIGHT,
    fromValueToCommonLayout: (value: PROP_ALIGNMENT): PROP_TEXT_ALIGNMENT =>
      value === PROP_ALIGNMENT.LEFT ? PROP_TEXT_ALIGNMENT.LEFT : PROP_TEXT_ALIGNMENT.RIGHT,
  },
  direction: {
    fromCommonLayoutToValue: (value: PROP_TEXT_ALIGNMENT): PROP_DIRECTION =>
      value === PROP_TEXT_ALIGNMENT.CENTER || value === PROP_TEXT_ALIGNMENT.LEFT
        ? PROP_DIRECTION.LTR
        : PROP_DIRECTION.RTL,
    fromValueToCommonLayout: (value: PROP_DIRECTION): PROP_TEXT_ALIGNMENT =>
      value === PROP_DIRECTION.LTR ? PROP_TEXT_ALIGNMENT.LEFT : PROP_TEXT_ALIGNMENT.RIGHT,
  },
  layout: {
    fromValueToCommonLayout: (value: PROP_LAYOUT): PROP_LABEL_POSITION =>
      value === PROP_LAYOUT.VERTICAL ? PROP_LABEL_POSITION.TOP : PROP_LABEL_POSITION.BOTTOM,
    fromCommonLayoutToValue: (value: PROP_LABEL_POSITION): PROP_LAYOUT =>
      value === PROP_LABEL_POSITION.TOP || value === PROP_LABEL_POSITION.SIDE
        ? PROP_LAYOUT.VERTICAL
        : PROP_LAYOUT.HORIZONTAL,
  },
  buttonAlignment: {
    fromValueToCommonLayout: (value: PROP_DIRECTION): PROP_LABEL_POSITION =>
      value === PROP_DIRECTION.LTR ? PROP_LABEL_POSITION.TOP : PROP_LABEL_POSITION.BOTTOM,
    fromCommonLayoutToValue: (value: PROP_LABEL_POSITION): PROP_DIRECTION =>
      value === PROP_LABEL_POSITION.TOP || value === PROP_LABEL_POSITION.SIDE
        ? PROP_DIRECTION.LTR
        : PROP_DIRECTION.RTL,
  },
  shapeSpacing: {
    fromCommonLayoutToValue: (spacing: number): number => Math.max(1, Math.min(spacing, 50)),
  },
  buttonSize: {
    fromCommonLayoutToValue: (size: number): number => Math.max(6, Math.min(size / 1.5, 100)),
    fromValueToCommonLayout: (size: number) => size * 1.5,
  },
  shapeSize: {
    fromCommonLayoutToValue: (size: number) => Math.max(15, Math.min(size, 100)),
  },
}

const similarProps: string[][] = [
  ['textAlignment', 'labelAlignment', 'filesAlignment', 'alignment', 'direction'], // dominated type: textAlignment
  ['layout', 'labelPosition', 'buttonAlignment'], // dominated type: labelPosition
  ['textPadding', 'spacing'], // dominated type: number (limits: 0 - 100 , 0 - 100)
  ['labelPadding', 'titlePaddingStart'], // dominated type: number (limits: 0 - 100, 0 - 100)
  ['labelMargin', 'shapeSpacing', 'buttonsMargin', 'titleMarginBottom'], // dominated type: number (limits: 0 - 100, 1 - 50 , 0 - 100, 0 - 100)
  ['buttonSize', 'shapeSize'], // dominated type: number (limits: 6 - 100, 15 - 100),
]

const componentTypeToProps: {
  [key in COMPONENT_TYPES]?: string[]
} = {
  [COMPONENT_TYPES.TEXT_INPUT]: ['textAlignment', 'textPadding', 'labelPadding', 'labelMargin'],
  [COMPONENT_TYPES.TEXT_AREA_INPUT]: [
    'textAlignment',
    'textPadding',
    'labelPadding',
    'labelMargin',
  ],
  [COMPONENT_TYPES.RADIO_GROUP]: [
    'alignment',
    'labelMargin',
    'buttonsMargin',
    'spacing',
    'buttonSize',
    'layout',
  ],
  [COMPONENT_TYPES.CHECKBOX_GROUP]: [
    'alignment',
    'labelMargin',
    'buttonsMargin',
    'spacing',
    'buttonSize',
    'layout',
  ],
  [COMPONENT_TYPES.SINGLE_CHECKBOX]: ['alignment', 'spacing', 'buttonSize'],
  [COMPONENT_TYPES.COMBOBOX]: ['textAlignment', 'textPadding', 'labelPadding', 'labelMargin'],
  [COMPONENT_TYPES.RATING]: [
    'labelAlignment',
    'shapeSpacing',
    'shapeSize',
    'labelPosition',
    'direction',
  ],
  [COMPONENT_TYPES.DATE_PICKER]: ['textAlignment', 'textPadding', 'labelPadding', 'labelMargin'],
  [COMPONENT_TYPES.FILE_UPLOADER]: [
    'filesAlignment',
    'labelPadding',
    'labelMargin',
    'buttonAlignment',
  ],
  [COMPONENT_TYPES.SIGNATURE_INPUT]: ['direction', 'titleMarginBottom', 'titlePaddingStart'],
}

const similarComponentTypes: COMPONENT_TYPES[][] = [
  [COMPONENT_TYPES.RADIO_GROUP, COMPONENT_TYPES.CHECKBOX_GROUP],
  [COMPONENT_TYPES.TEXT_INPUT, COMPONENT_TYPES.DATE_PICKER, COMPONENT_TYPES.COMBOBOX],
  [COMPONENT_TYPES.FILE_UPLOADER],
  [COMPONENT_TYPES.RATING],
  [COMPONENT_TYPES.SINGLE_CHECKBOX],
  [COMPONENT_TYPES.TEXT_AREA_INPUT],
  [COMPONENT_TYPES.SIGNATURE_INPUT],
]

const getSimilarPropsValues = (fields: FormField[], similarPropsKeys: string[]): PROPERTIES[] => {
  const similarPropsValues = fields.map(({ componentType, ...fieldProperties }) => {
    const fieldPropsInSimilarPropsKeys: string[] = getFieldPropsInSimilarPropsKeys(
      componentType,
      similarPropsKeys
    )
    return fieldPropsInSimilarPropsKeys
      .map(prop => {
        const fromValueToCommonLayout = _.get(
          propsTransformationToCommonLayout,
          [prop, 'fromValueToCommonLayout'],
          _.identity
        )
        return fromValueToCommonLayout(fieldProperties[prop])
      })
      .filter(value => !(value === undefined))
  })
  return _.flatMap(similarPropsValues)
}

const getSimilarPropsValue = (fields: FormField[]) =>
  similarProps.map(props => calcDominatedValueInArray(getSimilarPropsValues(fields, props)))

const getPropIndex = (propKey: string): number =>
  _.findIndex(similarProps, props => _.includes(props, propKey))
const getFieldPropsInSimilarPropsKeys = (
  componentType: COMPONENT_TYPES,
  similarPropsKeys: string[]
) => _.intersection(componentTypeToProps[componentType], similarPropsKeys)

const getCommonFieldProps = (fields: FormField[], propsToCheck: string[], fieldProps: any) => {
  const similarPropsValue = getSimilarPropsValue(fields)
  const propsFromFields = propsToCheck.reduce((acc, propKey) => {
    const propIndex = getPropIndex(propKey)
    const fromCommonLayoutToValue = _.get(
      propsTransformationToCommonLayout,
      [propKey, 'fromCommonLayoutToValue'],
      _.identity
    )
    acc[propKey] =
      similarPropsValue[propIndex] !== undefined
        ? fromCommonLayoutToValue(similarPropsValue[propIndex])
        : _.get(fieldProps, propKey)
    return acc
  }, {})
  return { ...fieldProps, ...propsFromFields }
}

const getFieldPropsFromSimilarField = (
  similarField: FormField,
  propsToCheck: string[],
  fieldProps: any
) => {
  const propsFromSimilarFields = propsToCheck.reduce((acc, propKey) => {
    acc[propKey] = similarField[propKey]
    return acc
  }, {})
  if (similarField.inputHeight) {
    propsFromSimilarFields['inputHeight'] = similarField.inputHeight
  }
  return { ...fieldProps, ...propsFromSimilarFields }
}

const getFieldProps = (
  componentType: COMPONENT_TYPES,
  fields: FormField[],
  similarField: FormField | undefined,
  fieldProps: any
) => {
  const propsToCheck = componentTypeToProps[componentType]
  if (!propsToCheck) {
    return fieldProps
  }
  return similarField
    ? getFieldPropsFromSimilarField(similarField, propsToCheck, fieldProps)
    : getCommonFieldProps(fields, propsToCheck, fieldProps)
}

const getFieldLabel = (
  similarField: FormField | undefined,
  fieldType: FieldPreset,
  fieldData
): string | undefined => {
  const showLabel = _.get(similarField, 'showLabel') || (!similarField && fieldData.label)
  return showLabel ? getDefaultLabel({ fieldType, ...fieldData }) : undefined
}

const getFieldLayout = (similarField: FormField | undefined, fieldComponent) => {
  const width = _.get(similarField, 'width')
  const height = _.get(similarField, 'height')
  return width !== undefined && height !== undefined
    ? _.assign({}, fieldComponent.layout, {
        width,
        height,
      })
    : fieldComponent.layout
}

const getFieldLayoutResponsive = (
  similarField: FormField | undefined,
  fieldComponent
): ResponsiveLayout => {
  const similarComponentLayout = _.get(similarField, 'layoutResponsive.componentLayouts[0]')
  return similarComponentLayout
    ? {
        ...fieldComponent.layoutResponsive,
        componentLayouts: [similarComponentLayout],
      }
    : fieldComponent.layoutResponsive
}

const getSimilarComponentsByType = (
  componentType: COMPONENT_TYPES,
  fields: FormField[]
): FormField | undefined => {
  const exactFields = fields.filter(field => field.componentType === componentType)
  if (exactFields.length) {
    return _.last(exactFields)
  }
  const componentTypeGroup = _.find(similarComponentTypes, group =>
    _.includes(group, componentType)
  )
  const similarFields = fields.filter(field => _.includes(componentTypeGroup, field.componentType))
  return _.last(similarFields)
}

export const createFieldWithMostCommonLayout = (
  fieldType: FieldPreset,
  fields: FormField[],
  fieldComponent,
  isResponsive: boolean = false
) => {
  const componentType = ALL_FIELDS_DATA[fieldType].properties.componentType
  const similarField = getSimilarComponentsByType(componentType, fields)

  const newField = _.cloneDeep(fieldComponent)
  if (isResponsive) {
    newField.layoutResponsive = getFieldLayoutResponsive(similarField, fieldComponent)
  } else {
    newField.layout = getFieldLayout(similarField, fieldComponent)
  }

  newField.data.label = getFieldLabel(similarField, fieldType, fieldComponent.data)
  newField.props = getFieldProps(componentType, fields, similarField, fieldComponent.props)

  return newField
}

export const getComponentTypeLayoutProp = (
  componentType: COMPONENT_TYPES,
  propKey: string,
  propValue: PROPERTIES
): FieldLayoutProp => {
  const propIndex = getPropIndex(propKey)
  const fieldPropsInSimilarPropsKeys = getFieldPropsInSimilarPropsKeys(
    componentType,
    similarProps[propIndex]
  )

  return fieldPropsInSimilarPropsKeys.length
    ? fieldPropsInSimilarPropsKeys.reduce((acc, propKey) => {
        const fromCommonLayoutToValue =
          _.get(propsTransformationToCommonLayout[propKey], 'fromCommonLayoutToValue') || _.identity
        acc[propKey] = fromCommonLayoutToValue(propValue)
        return acc
      }, {})
    : null
}
