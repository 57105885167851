import { GeneralNumber } from '../../general-fields/definitions/general-number'
import { FormsFieldPreset } from '../../../../../../constants/field-types'

export class CrmNumber extends GeneralNumber {
  protected get fieldType() {
    return FormsFieldPreset.CRM_NUMBER
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.GENERAL_NUMBER}`)
  }
}
