import { getApi } from './editor-ready/editor-ready'
import * as _ from 'lodash'
import { getBiLogger, getPanelDefinitions } from './editor-app-impl'
import { EVENTS } from '../constants/bi'

export const openAddFormPanel = async () => {
  const api = await getApi()
  api.managePanels.openAddFormPanel(getPanelDefinitions()) // no await, resolved when panel is closed
  getBiLogger().log({ evid: EVENTS.PANELS.addFormPanel.OPEN_PANEL })
}

export const openDynamicFieldSettingsPanel = async (componentRef: ComponentRef, onOpen: ({}) => void) => {
  const api = await getApi()
  api.managePanels.openDynamicFieldSettingsPanel(componentRef, onOpen)
}

export const openComponentPanel = async (componentRef, panelName, onOpen = _.noop, externalData?) => {
  const api = await getApi()
  api.managePanels.openComponentPanel(componentRef, panelName, getPanelDefinitions(), onOpen, externalData)
}

export const openManageSubscribersPanel = async () => {
  const api = await getApi()
  api.managePanels.openManageSubscribersPanel(getPanelDefinitions(), { startBi: { action: 'Manage Subscribers' } })
}

export const openHelpPanel = async (helpId, onOpen = _.noop) => {
  const api = await getApi()
  api.managePanels.openHelpPanel(helpId, onOpen)
}

export const addForm = async (
  preset,
  {
    containerRef = null,
    source_name = null,
    select_form = true
  } = {}
) => {
  const api = await getApi()
  return api.addForm.addForm(preset, {
    containerRef,
    source_name,
    select_form
  })
}
