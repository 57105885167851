import { FormsFieldPreset, FieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { iconNames } from '../../icons/types'
import { FormPlugin } from '../../../../../../constants/plugins'
import { FieldRenderConfigType } from '../../constants'
import { IGeneralField } from '../../general-field'

export class GeneralUploadButton extends IGeneralField {
  protected get customFields() {
    return []
  }

  protected get icon() {
    return iconNames.fileUpload
  }

  protected get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_UPLOAD_BUTTON
  }

  protected get collectionFieldType() {
    return FieldCollectionType.IMAGE
  }

  protected get componentType() {
    return COMPONENT_TYPES.FILE_UPLOADER
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_FILE_UPLOAD
  }

  protected get data(): any {
    return {
      buttonLabel: this.translate('preset.fileUploadButtonLabel'),
      placeholderLabel: this.translate('preset.fileUploadPlaceholderLabel'),
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        crmSync: FieldRenderConfigType.REMOVE,
      }
    }
  }

  public get base() {
    return GeneralUploadButton.prototype
  }
}
