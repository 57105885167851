import { FORMS_APP_DEF_ID } from '../../../constants'

const DEFAULT_CONTROLLER_LAYOUT = { x: 100, y: 20 }

export const APP_CONTROLLER_DEFINITION = {
  componentType: 'platform.components.AppController',
  layout: DEFAULT_CONTROLLER_LAYOUT,
  data: {
    controllerType: 'singlePostController',
    applicationId: FORMS_APP_DEF_ID,
    name: 'wix-forms-controller',
    settings: JSON.stringify({
      dataset: {
        collectionName: 'test',
        readWriteType: 'READ',
        filter: null,
        sort: null,
      },
    }),
  },
}
export const APP_WIDGET_DEFINITION = {
  componentType: 'platform.components.AppWidget',
  style: 'appWidget1',
  data: {
    type: 'AppController',
    name: 'wix-forms-controller',
    applicationId: FORMS_APP_DEF_ID,
  },
}
